<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <v-card-title class="elevation-0">
          <span class="itemHeading">Application</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-layout wrap justify-start v-if="data">
          <v-flex xs12 align-self-center pa-4>
            <v-layout wrap>
              <v-flex xs12 align-self-center>
                <v-card color="#F5F5DC" tile>
                  <v-layout wrap pa-2 justify-start v-if="data">
                    <v-flex xs12 align-self-start text-left pa-2>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          text-left
                          pa-1
                          v-if="data.employeeId"
                        >
                          <span class="kumbhRegular"> Applicant : </span>
                          <v-btn
                            small
                            dark
                            plain
                            color="#005f32"
                            :to="'ViewEmployee?id=' + data.employeeId._id"
                          >
                            <span
                              style="font-size: 22px"
                              class="kumbhMedium vhover"
                              v-if="data.employeeId.name"
                            >
                              {{ data.employeeId.name }}
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 align-self-center py-4>
                <v-layout wrap justify-center class="kumbhRegular" style="font-size: 17px">
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Date of apply :
                      <span class="kumbhMedium" v-if="data.create_date">
                        {{ data.create_date.slice(0, 10) }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Type :
                      <span class="kumbhMedium" v-if="data.type">
                        {{ data.type }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Amount (INR) :
                      <span class="kumbhMedium" v-if="data.amount">
                        {{ data.amount }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-1>
                    <span
                      >Status :
                      <span class="kumbhMedium" v-if="data.status">
                        {{ data.status }}</span
                      >
                    </span>
                  </v-flex>

                  <v-flex xs12   align-self-center text-left pa-1>
                    <span
                      >Description :
                      <span class="kumbhMedium" v-if="data.description">
                        {{ data.description }}</span
                      >
                    </span>
                  </v-flex>
                  <v-flex xs12 align-self-center>
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex xs12 sm8 md6 align-self-center pa-6 py-12 v-if="data.status == 'Pending'">
                    <v-layout wrap>
                      <v-flex xs6 align-self-center text-right pa-4>
                        <v-dialog
                          persistent
                          v-model="data.approve"
                          max-width="600px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              style="
                                font-family: kumbhBold;
                                border-bottom: 3px solid #005f32;
                              "
                              class="mr-2"
                              plain
                              depressed
                              dark
                              block
                              v-on="on"
                              v-bind="attrs"
                            >
                              <span style="color: #005f32">Approve</span>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-layout wrap>
                              <v-flex
                                xs12
                                align-self-center
                                pa-4
                                style="background: #005f32"
                                text-left
                              >
                                <span class="kumbhBold" style="color: #ffffff"
                                  >Confirmation</span
                                >
                              </v-flex>
                            </v-layout>
                            <v-card-title
                              >Are you sure you want to approve this
                              application?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="error"
                                tile
                                dark
                                @click="data.approve = false"
                                >Cancel</v-btn
                              >
                              &nbsp;
                              <v-btn
                                color="#005f32"
                                outlined
                                tile
                                @click="approveLoan('Approved')"
                                >Approve</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>

                      <v-flex xs6 align-self-center text-left pa-4>
                        <v-dialog
                          persistent
                          v-model="data.reject"
                          max-width="600px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              style="
                                font-family: kumbhBold;
                                border-bottom: 3px solid red;
                              "
                              class="mr-2"
                              plain
                              block
                              depressed
                              dark
                              v-on="on"
                              v-bind="attrs"
                            >
                              <span style="color: red">Reject</span>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-layout wrap>
                              <v-flex
                                xs12
                                align-self-center
                                pa-4
                                style="background: #005f32"
                                text-left
                              >
                                <span class="kumbhBold" style="color: #ffffff"
                                  >Confirmation</span
                                >
                              </v-flex>
                            </v-layout>
                            <v-card-title
                              >Are you sure you want to reject this
                              application?</v-card-title
                            >
                            <v-layout wrap pa-6>
                              <v-flex xs12 align-self-center pa-2>
                                <v-textarea 
                                label="Remarks"
                                v-model="remarks"
                                outlined
                                hide-details
                                ></v-textarea>
                              </v-flex>
                            </v-layout>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="error"
                                tile
                                dark
                                @click="data.reject = false"
                                >Cancel</v-btn
                              >
                              &nbsp;
                              <v-btn
                                color="#005f32"
                                outlined 
                                tile
                                @click="approveLoan('Rejected')"
                                >Reject</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,

      pageCount: 1,
      appLoading: false,

      currentPage: 1,

      data: [],
      remarks:null,
    };
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/salaryAdvance_Loans/get",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    approveLoan(status) {
      this.appLoading = true;
       
      axios({
        url: "/salaryAdvance_Loans/approve/reject",
        method: "POST",
        data: {
          id: this.$route.query.id,
          status:status,
          remarks:this.remarks
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if(response.data.status)
          this.$router.push('/LoanApplications')
          else {
            this.msg=response.data.msg
            this.showsnackbar=true
            this.getData()
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
